<template>
    <div>

        <!-- Form Modal -->
        <b-modal id="modal-season-form" ref="myModalLayout" centered no-close-on-backdrop no-close-on-esc size="lg"
            @ok="submitLayout">
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5 v-if="layout.id === null">Add Layout</h5>
                <h5 v-if="layout.id !== null">Edit Layout</h5>

                <feather-icon class="ml-4 pr-0 cursor-pointer" icon="XIcon" size="16" @click="close()" />
            </template>

            <!-- Custom Modal Footer -->
            <template #modal-footer="{ ok, cancel }">
                <b-button size="md" variant="primary" @click="ok()" :disabled="saving">
                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                    <span v-if="!saving">Save</span>
                    <span v-if="saving">Saving...</span>
                </b-button>
                <b-button size="md" @click="cancel()" variant="outline-secondary">
                    Cancel
                </b-button>
            </template>

            <!-- Data Form in Modal Body -->
            <template #default="{ }">
                <validation-observer ref="dataForm" #default="{ invalid }">
                    <b-form ref="form" @submit.stop.prevent="handleCreateLayout">

                        <b-row>
                            <b-col cols="12">
                                <b-form-group labe-for="name" label="Name">
                                    <validation-provider #default="{ errors }" name="name" rules="required">
                                        <b-form-input id="name" name="name" type="text" v-model="layout.name"
                                            :state="errors.length > 0 ? false : null" size="md" value="" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.name">{{
                                            serverErrors.name[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <!-- <b-col cols="3">
                                <b-form-group labe-for="name" label="Partition Name">
                                    <validation-provider #default="{ errors }" name="name" rules="required">
                                        <b-form-input id="name" name="name" type="text" v-model="layout.partition.name"
                                            :state="errors.length > 0 ? false : null" size="md" value="" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.name">{{
                                            serverErrors.name[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col> -->
                            <b-col cols="3" v-for="element, index in  layout.partition" :key="index">
                                <b-form-group labe-for="name" label="Folow Partition">
                                    <validation-provider #default="{ errors }" name="name" rules="required">
                                        <b-form-input id="name" name="name" type="text" v-model="element.name"
                                            :state="errors.length > 0 ? false : null" size="md" value="" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.name">{{
                                            serverErrors.name[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="2">
                                <div class="mt-2" style="margin-top: 30px !important">
                                    <Button icon="pi pi-angle-double-right" rounded outlined aria-label="Filter"
                                        @click="showMoreInpunt()" />
                                </div>

                            </b-col>

                            

                           
                        </b-row>



                    </b-form>
                    <!-- <pre>{{ layout }}</pre> -->

                </validation-observer>
            </template>
        </b-modal>
        <!-- ./Form Modal -->

        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search & Button -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..."
                                size="md" />
                            <b-button variant="primary" size="md" @click="invokeLayoutsForm()">
                                <span class="text-nowrap">Add Layout</span>
                            </b-button>
                        </div>
                    </b-col>
                    <!-- ./Search & Button-->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table striped hover small ref="records" class="position-relative" :items="fetch" responsive :fields="columns"
                primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No records found"
                :sort-desc.sync="isSortDirDesc">


                <template #cell(partition)="data">
                    <Timeline :value="data.item.partition" layout="horizontal" align="top">
                        <template #content="slotProps">
                            {{ slotProps.item.name }}
                        </template>
                    </Timeline>
                </template>


                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>

                        <b-dropdown-item @click="invokeUpdateForm(data.item)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="confirmDelete(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
    BForm, BFormGroup,
    BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem,
    BSpinner, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import store from '@/store'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import layoutsStoreModule from '@/views/cromis/warehouse/storage_layouts/layoutsStoreModule'
import useLayoutsList from '@/views/cromis/warehouse/storage_layouts/useLayoutsList'
import Button from 'primevue/button';
import Timeline from 'primevue/timeline';

export default {
    props: {},
    components: {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem,
        BSpinner,
        ValidationObserver, ValidationProvider, BFormCheckbox, Button,Timeline
    },
    directives: {},
    setup(props, context) {
        const dataForm = ref(null)
        const myModal = ref(null)
        const myModalLayout = ref(null)
        const saving = ref(false)
        const serverErrors = ref(null)
        const selections = ref([])
        const formSelections = ref([])
        const levels = ref(null)
        const children = ref([])
        const warehouses = ref([])
        const societies = ref([])
        const documents = ref([])
        const selectedCompany = ref(null)


        const layout = ref({
            name: null,
            partition: [
                
            ]

        })

        const CROMIS_STORE_MODULE_NAME = 'cromis-layout'

        // Register module
        if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, layoutsStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
        })

        onMounted(async () => {

            await store.dispatch('cromis-layout/warehouses')
                .then(response => {
                    warehouses.value = response.data.warehouses
                    console.log(warehouses.value)
                })
                .catch(error => {
                    console.log('got error', error)
                })

            await store.dispatch('cromis-layout/societies')
                .then(response => {
                    societies.value = response.data.societies
                    console.log(societies.value)
                })
                .catch(error => {
                    console.log('got error', error)
                })
            await store.dispatch('cromis-layout/documents')
                .then(response => {
                    response.data.documents.forEach(doc => {
                        if (doc.display) {
                            documents.value.push(doc)
                        }
                    });

                    console.log(documents.value)
                })
                .catch(error => {
                    console.log('got error', error)
                })

        })



        const {
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            // Filers
            locationFilter,
        } = useLayoutsList()

        const showMoreInpunt = () => {
            layout.value.partition.push({ id: '', name: '' })
        }


        // Form Methods
        const invokeUpdateForm = (item) => {
            serverErrors.value = null
            console.log(item)
         
            layout.value = {
                id: item.id,
                name: item.name,
                partition: item.partition
            }
            myModalLayout.value.show()
        }

        const invokeLayoutsForm = () => {
            serverErrors.value = null

            layout.value = {
                id: null,
                name: null,
                partition: [
                   {
                    id: '',
                    name: ''
                   }
                ]
            }
            

            myModalLayout.value.show()
        }

        const isFormValid = async () => {
            let isValid = false
            await dataForm.value.validate().then(success => {
                isValid = success
            })

            return isValid
        }

        const submit = (bvModalEvt) => {
            bvModalEvt.preventDefault()


            serverErrors.value = null
            // Handle form submit
            if (layout.value.id === null || layout.value.id === 0)
                handleCreate()
            else
                handleUpdate(sector.value)
        }

        const handleCreate = async () => {
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }



        }


        const submitLayout = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null

            if (layout.value.id === null || layout.value.id === 0)
                handelLayoutCreate()
            else
                handleUpdate(layout.value)

        }

        const handleUpdate = async (item) => {
            saving.value = true
            const valid = await isFormValid()
            console.log(item)
            if (!valid) {
                saving.value = false
                return
            }

            await store.dispatch('cromis-layout/update', { id: item.id, data: item })
                .then(response => {

                    console.log(response)
                    refetch()
                    saving.value = false

                    myModalLayout.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to Layouts has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    console.log(error)
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })

        }

        const handelLayoutCreate = async () => {

            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }
            layout.value.sourceType = parseInt(layout.value.sourceType)
            console.log(layout.value.sourceType)

            await store.dispatch('cromis-layout/create', layout.value)
                .then(response => {
                    refetch()
                    saving.value = false

                    context.root.$swal({
                        icon: 'success',
                        text: 'The Layout has been added successfully!',
                        showConfirmButton: true,
                        timer: 5000,
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                    layout.value = {
                        id: null,
                        source_id: null,
                        destination_id: null,
                        distance: null,
                    }
                    myModalLayout.value.hide()
                })
                .catch(error => {
                    console.log(error)
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }


        const confirmDelete = (item) => {
            context.root.$swal({
                title: 'Are you sure?',
                text: `Layout  will be deleted.`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                customClass: {
                    confirmButton: 'btn btn-outline-danger',
                    cancelButton: 'btn btn-outline-primary ml-1',
                },
                buttonsStyling: false,
            }).then(async result => {
                if (result.value) {
                    let success = await remove(item)

                    if (success) {
                        context.root.$swal({
                            icon: 'info',
                            title: 'Deleted!',
                            text: 'Layout has been deleted.',
                            customClass: {
                                confirmButton: 'btn btn-outline-info',
                            },
                        })
                    }
                }
            })
        }

        const remove = async (id) => {
            let success = false

            await store.dispatch('cromis-layout/remove', id)
                .then(response => {
                    success = true
                    refetch()
                })
                .catch(error => {
                    success = false
                    context.root.$swal({
                        icon: 'error',
                        title: 'Server Error',
                        text: 'Something went wrong. See tech support',
                        showConfirmButton: false,
                        customClass: {
                            confirmButton: 'btn btn-danger',
                        },
                        buttonsStyling: false,
                    })
                })

            return success
        }

        return {
            // Data
            selections,
            formSelections,
            levels,
            children,
            layout,
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            // Methods
            required,
            saving,
            serverErrors,
            dataForm,
            myModal,
            myModalLayout,
            locationFilter,
            invokeUpdateForm,
            submit,
            submitLayout,
            isFormValid,
            handleCreate,
            handleUpdate,
            remove,
            confirmDelete,
            warehouses,
            selectedCompany,
            invokeLayoutsForm,
            documents,
            societies,
            showMoreInpunt
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>